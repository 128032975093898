<template>
    <div class="Feamization">
        <div class="Feamiza_div">
        <div class="ul_divsss">
            <div class="ke_ul">
                <div class="ke_ul_left">
                    <p class="title_divp">投票选择</p>
                    <div class="lable_div">
                        <strong class="lable_div_strong">最赞赏的</strong>
                        <button class="lable_div_butt">
                            <span>
                                已投票
                            </span>
                        </button>
                    </div>
                </div>
                <div class="ke_ul_right clearfix">
                    <div class="fl ke_ul_r_div">
                        <p class="title_divp">我们：最明确的</p>
                        <div class="content_dov">
                            <div class="content_daa">
                                <p v-for="(item,index) in conte" :key="index">
                                    <span>{{index + 1 }}</span>、
                                    {{item.zmqd}}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="fr ke_ul_r_div">
                        <p class="title_divp">我们：最新想到的</p>
                        <div class="content_dov">
                            <div class="content_daa">
                                <p v-for="(item1,index1) in conte" :key="index1">
                                    <span>{{index1 + 1 }}</span>、
                                    {{item1.zxxd}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ke_top clearfix">
                <div class="fl ke_top_left">
                    <p class="lable_p">排行榜</p>
                    <ul class="left_uls">
                        <li>
                            <div class="li_one hui">
                                排名
                            </div>
                            <div class="li_two lan" style="color:#fff">
                                <span>最赞赏的</span>
                            </div>
                            <div class="li_two hong" style="color:#fff">
                                <span>票数</span>
                            </div>
                        </li>
                        <li v-for="(item,index) in formList.length==0?5:formList" :key="index">
                            <div class="li_one qianhui">
                                <span>{{index+1}}</span>
                            </div>
                            <div class="li_two qianlan">
                                <span>{{item.name}}</span>
                            </div>
                            <div class="li_two qianhong">
                                <span>{{item.score}}</span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="righttop fr">
                    <div  ref="charts">

                    </div>
                    <p class="clearfix right_t_p">
                        <span class="fl right_t_one">
                            <i class="iconfonts icons-gupiao"></i>
                        </span>
                        <span class="fl right_text">
                            票数统计
                        </span>
                    </p>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>
<script>
import echarts from 'echarts'
export default {
    data() {
        return {
            timer:'',
            countNum:0,
            imgList:[],
            count:{},
            dialogVisible:false,
            innerVisible:false,
            dialogVisibleForm:false,
            activeName:'',
            mytitle:'',
            ruleForm:{
                id:'',
                type:''
            },
            Form:{
                name:''
            },
            typeTrue:true,
            projForm:{},
            rules:{
                id:[
                    { required: true, message: '请选择小组', trigger: 'change' }
                ],
                type:[
                    { required: true, message: '请选择赞赏原因', trigger: 'change' }
                ],
                name:[
                    { required: true, message: '内容不能为空', trigger: 'blur' }
                ]
            },
            itemList:[],
            valuList:{
                xAxis:[],
                top:[],
                last:[]
            },
            arrList:[],
            typeList:[
                {
                    id:1,
                    label:'创意新颖'
                },
                {
                    id:2,
                    label:'功能实用'
                },
                {
                    id:3,
                    label:'可行性高'
                }
            ],
            fp3:{
                users:{}
            },
            formList:{},
            conte:{
                zmqd:'',
                zxxd:''
            },
            a_show:false,
            pageNum: 1,
            pageTotalNum: 1
        }
    },
    methods: {
        getapp(){
            this.calId=this.$route.query.calId
            this.axios.projects8({
                token:this.$store.state.token,
                id:this.calId,
                tId:this.$store.state.tem_id
            }).then(res=>{
                if(res.data.code==0){
                    this.form = res.data.data
                    var str = this.form
                    this.$emit('info', str);
                    this.formList = res.data.data.data.p1.value
                    this.valuList = res.data.data.data.p2.value
                    this.fp3 = res.data.data.data.p3.users
                    this.conte = res.data.data.data.p4.users
                    this.mycharts()
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })
        },
        dk_Dt(val){
            this.imgList = val.image
            this.pageTotalNum = this.imgList.length
            this.innerVisible = true
        },
        // 上一页
        prePage() {
            let page = this.pageNum
            page = page > 1 ? page - 1 : this.pageTotalNum
            this.pageNum = page
        },
        // 下一页
        nextPage() {
            let page = this.pageNum
            page = page < this.pageTotalNum ? page + 1 : 1
            this.pageNum = page
        },
        toupiao(num){
            if(num==0){
                this.a_show = true
            }else{
                this.a_show = false
            }
            this.axios.StuCprojectgets8({
                token:this.$store.state.token,
                id:this.$store.state.cla_id
            }).then(res=>{
                if(res.data.code==0){
                    var numList = []
                    res.data.data.data.team.values.map(item=>{
                        if(item.id!=this.$store.state.team_id){
                            numList.push(item)
                        }
                    })
                    this.arrList = numList
                    this.activeName = this.arrList[0].name
                    this.dialogVisible = true
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })
        },
        handleClick(val){
            console.log(val)
        },
        mycharts(){
            let myChart =echarts.init(this.$refs.charts,"macarons");
            myChart.setOption(
                {
                    color:['#54AAD8','#FFD25B','#FF805B'],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow',
                            crossStyle: {
                                color: '#999'
                            }
                        },
                        position: function(pt) { //提示框的位置
                        return [pt[0], 30];
                        }
                    },
                     grid: { //图表和父盒子之间的距离
                        show: true,
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        top:'17%',
                        containLabel: true
                    },
                    legend: {//图例
                        top:'5%',
                        data: [
                            {
                                name:'创意新颖',
                                textStyle: {
                                    color: '#333',
                                    fontSize:14
                                }
                            },
                            {
                                name: '功能实用',
                                textStyle: {
                                    color: '#333',
                                    fontSize:14
                                }
                            },
                            {
                                name: '可行性高',
                                textStyle: {
                                    color: '#333',
                                    fontSize:14
                                }
                            }
                        ]
                    },
                    xAxis:{ //x轴
                        type: 'category',
                        // boundaryGap: false,
                        data: this.valuList.xAxis,
                        axisLabel: { 
                            interval:0,
                            textStyle: {
                                color: '#333',
                                fontSize :10
                            },
                            margin:8
                        },
                        axisLine:{
                            show :true,
                            // symbol: ['none', 'arrow'],
                            lineStyle:{
                                color:'rgb(2,121,253)'
                            }
                        },
                        axisTick:{
                            show :false
                        },
                        splitLine:{
                            show:false
                        }
                    },
                    yAxis:{
                        type: 'value',
                        axisLabel: { //x轴的坐标文字
                            show: true,
                            
                            textStyle: {
                                color: '#333' //文字的颜色
                            }
                        },
                        max: function (value) {
                            return Math.ceil(value.max + value.max * 0.1);
                        },
                        axisLine:{
                            show :true,
                            lineStyle:{
                                color:'rgb(2,121,253)'
                            }
                        },
                        axisTick:{
                            show :false,
                        },
                        splitLine:{  //坐标在grid区域的分割线
                        　 lineStyle: { //设置分割线的样式(图表横线颜色)
                                color: ['#153a8a']
                            }
                        }
                    },
                    series: [
                        {
                            name: '创意新颖',
                            type: 'bar', //柱状图
                            data: this.valuList.cyxy,
                            barWidth :'10%', //柱状体的宽度
                            itemStyle:{
                                normal:{
                                    label:{
                                        show:true,
                                        position:'top',
                                        textStyle:{
                                            color:'#333',
                                            fontSize:14
                                        }
                                    }
                                }
                            }
                        },
                        {
                            name: '功能实用',
                            type: 'bar',
                            data: this.valuList.gnsy,
                            barWidth :'10%',
                            itemStyle:{
                                normal:{
                                    label:{
                                        show:true,
                                        position:'top',
                                        textStyle:{
                                            color:'#333',
                                            fontSize:14
                                        }
                                    }
                                }
                            }  
                        },
                        {
                            name: '可行性高',
                            type: 'bar',
                            data: this.valuList.kxxg,
                            barWidth :'10%',
                            itemStyle:{
                                normal:{
                                    label:{
                                        show:true,
                                        position:'top',
                                        textStyle:{
                                            color:'#333',
                                            fontSize:14
                                        }
                                    }
                                }
                            }
                        }
                    ]
                }
            )
            //让图表自适应
            window.addEventListener("resize",function(){
                myChart.resize()  // myChart 是实例对象
            })
        },
        closeis(){
            this.innerVisible = false
        },
        handleClose(done){

        }
    },
    mounted() {
        this.getapp()
    }
}
</script>
<style scoped>
@import './../../../assets/css/teacher/Copypaper/CognitiveKXYH.css';
</style>
<style>
.form_image .el-carousel{
    width: 100%;
    height: 100%;
}
.form_image .el-carousel__container{
    height: 100%;
}
.form_image .el-carousel__arrow{
    display: none!important;
}
.form_image .el-carousel__arrow:hover{
    display: none!important;
}
.form_image .el-carousel__indicators{
    display: none;
}
.Feam_popup .el-tabs--border-card{
    box-shadow: none;
}
.Feam_popup .demo_dw{
    position: relative;
    padding-left: 30px;
    box-sizing: border-box;
}
.demo_dw_div{
    position:absolute;
    top: 0;
    right: 35px;
}
.Feam_popup .el-dialog{
    margin-top: 8vh!important;
}
.Feam_popup .el-dialog__body{
    padding: 20px 0px 10px;
}
.Feam_popup .el-dialog__header{
    padding-top: 10px;
    text-align: center;
    border-bottom: 1px solid #DCDFE6;
    background: #4DA3D2;
}
.Feam_popup .el-dialog__title{
    font-size: 16px;
    font-weight: bold;
    color:#fff;
}
.Feam_popup .el-form-item__label{
    font-weight: bold;
    color: #333;
    text-align: right;
    float: none;
    display: inline-block;
        vertical-align: top;
}
.Feam_popup .el-form-item__content{
    margin-left: 0!important;
    text-align: center;
    display: inline-block;
}
.Feam_popup .el-form-item--small.el-form-item{
    margin-bottom: 22px;
    text-align: center;
}
.Feam_popup .left_div_f .el-form-item--small.el-form-item{
    text-align: left;
}
.Feam_popup .el-dialog__footer{
    text-align: center;
}
.Feam_popup .left_div_f .el-textarea__inner{
    width: 370px;
}
.Feam_popup .left_div_t .el-input__inner{
    width: 370px;
}
.Feam_popup .form_div .el-textarea__inner{
    width: 350px;
}
.Feam_popup .el-textarea__inner{
    width: 500px;
}
.Feam_popup .el-button--mini{
    width: 80px;
}
.Feam_popup .el-descriptions-item__label.is-bordered-label{
    width: 18%;
}
</style>